import axios from 'axios'

async function check(store, gtm) {
  // ログイン時のみ対応
  if (store.getters['auth/isLogin']) {
    // ユーザーID送信
    // https://qiita.com/TK-C/items/401dc403c6f3af6ad086
    // gtm.push({ user_id: store.state.auth.company.uid })

    // デターの同期チェック
    store.dispatch('auth/mstCheckUpdate')

    // トラッキングコード同期
    store.dispatch('tracking_code/sync')

    // oneSignal
    store.dispatch('auth/updateOneSignalDeviseToken')

    // Firebase
    window.storeCtl.dispatch('fireStore/init', true)
  } // ログイン

  // アプリ・バージョンアップ
  try {
    // version.jsonファイルから最新のバージョンを取得
    const res = await axios.get('/version.json')
    const data = res.data
    const latestVersion = data.version

    // 環境変数から現在のバージョンを取得
    const curVersion = !!process.env.VERSION ? Number(process.env.VERSION) : 0
    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion) {
      window.location.reload(true)
    } else {
    }
  } catch (error) {
    console.error(error)
    // エラーのときはなにもしない
  }

  // notion
  // store.dispatch("notion/getDocs", "リリース")
}

// まずSettimeoutで初期チェック、その後1分毎に自動チェック
export default async ({ store, route, query, $gtm }) => {
  // トラッキングコードセット
  // トラッキングコードがなければOtherにセット
  if (route.name === 'user-uuid') {
    store.commit('tracking_code/setCode', `c_${route.params.uuid}`)
  } else if (route.name === 'post-uuid') {
    store.commit('tracking_code/setCode', `p_${route.params.uuid}`)
  } else if (route.name === 'tag-name') {
    store.commit('tracking_code/setCode', `t_${route.params.name}`)
  } else if (query.tc) {
    store.commit('tracking_code/setCode', query.tc)
  } else {
    store.commit('tracking_code/setCode', `other`)
  }

  // useragnet
  window.setTimeout(async () => {
    // check useragent
    const ua = navigator.userAgent
    let isIosApp = !!ua.match(/iOS_App/)
    store.commit('userAgent/set', { isIosApp: isIosApp, ua: ua })
    let isTouchDevice = false
    if (/android/i.test(ua)) isTouchDevice = true
    if (/iPad|iPhone|iPod/i.test(ua) || isIosApp) isTouchDevice = true
    store.commit('devise/setIsTouchDevice', isTouchDevice)

    // Notion
    store.dispatch('notion/getDocs', 'リリース')
    store.dispatch('notion/getDocs', 'ガイド')

    // ログインしていたらマスタの更新
    if (store.getters['auth/isLogin']){
      store.dispatch('auth/refreshToken')
      store.dispatch("auth/mstForceUpdate")
    }
  }, 1000)

  // 起動時だと処理できない箇所があったので５秒後
  window.setTimeout(async () => {
    // onesignal
    console.log('start get Onesignal')
    $OneSignal.push(async () => {
      console.log('start Onesignal subscriptionChange')
      await $OneSignal.on('subscriptionChange', async isSubscribed => {
        console.log('Onesignal subscriptionChange done', isSubscribed)
        if (isSubscribed) {
          const deviceId = await $OneSignal.getUserId()
          console.log('onesignel deviceId', deviceId)
          localStorage.setItem('onesignal_user_id', deviceId)
        } else {
          $OneSignal.showNativePrompt()
        }
      })
    })
    $OneSignal.push(async () => {
      console.log('start Onesignal isPushNotificationsEnabled')
      await $OneSignal.isPushNotificationsEnabled(async isEnabled => {
        console.log('Onesignal isPushNotificationsEnabled done', isEnabled)
        if (isEnabled) {
          const deviceId = await $OneSignal.getUserId()
          console.log('onesignel deviceId', deviceId)
          localStorage.setItem('onesignal_user_id', deviceId)
        } else {
          $OneSignal.showNativePrompt()
        }
      })
    })

    // 諸々のチェック
    check(store, $gtm)
  }, 1000 * 3)

  // 5分に1回
  window.setInterval(
    () => {
      check(store)
    },
    1000 * 60 * 5
  )
}
