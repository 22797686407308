
import dayjs from 'dayjs';
import Common from '~/mixins/common'
import _ from 'lodash';
import { form_config } from '~/utils/models'
import { httpClient } from '~/utils/axios'
import { clientStatusOptions, planTypeOptions, reserveUnitOptions, sexOptions, subscTypeOptions, listExtendForMonthlyOptions, nextSubscTicketEndStyles } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import ClientCard from '~/components/common/client/Card'
import ImageFileInput from '~/components/util/ImageFileInput'
import Tabs from '~/components/util/Tabs'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    ClientCard,
    ImageFileInput,
    Tabs,
  },
  props: {
  },
  data() {
    return {
      img_icon: null,

      showEditClientModal: false,      
      original_client: null,
      edit_client: null,
      edit_client_change: false,
      tabIndex: 0,

      planTypeOptions: planTypeOptions,
      reserveUnitOptions: reserveUnitOptions,
      statusOptions: clientStatusOptions,
      sexOptions: sexOptions,
      subscTypeOptions: subscTypeOptions,
      listExtendForMonthlyOptions: listExtendForMonthlyOptions,
      nextSubscTicketEndStyles: nextSubscTicketEndStyles,
      handleChangeNextSubscDate: false,
      handleChangeTicket: false,
      handleChangeCourse: false,

      tabOptions: [
        {label: "プロフィール", value: 0, disabled: false},
        {label: "会員設定", value: 1, disabled: false},
        // {label: "目標", value: 2, disabled: false},
        {label: "各記録", value: 3, disabled: false},
        {label: "チケット・予約", value: 4, disabled: false},
        {label: "定期購入", value: 5, disabled: false},
        {label: "掘り起こし", value: 6, disabled: false},
        {label: "支払い情報", value: 7, disabled: false},
      ],
    }
  },
  async mounted() {
  },
  watch: {
    edit_client: {
      handler( newData, oldData ){
        console.log("watch edit_client_change", newData)
        this.edit_client_change = true
      },
      deep: true
    },
  },
  computed: {
    currentFilterdCourses(){
      let courses = _.filter(this.courses, c => {
        if(c.use_new_version){
          return !c.only_for_buy
        }else{
          return true
        }
      })
      return courses
    },
  },
  methods: {
    changeTab(event){
      console.log("changeTab",event)
      let self = this
      if(this.edit_client_change === true){
        self.$bvModal.msgBoxConfirm('変更した内容が保存さていませんがタブを移動してよろしいですか？', {
          okTitle: 'はい',
          cancelTitle: 'いいえ',
          centered: true,
        }).then(async confirm => {
          if(confirm){
            this.edit_client = _.cloneDeep(this.original_client)
            setTimeout(()=>{
              this.edit_client_change = false
            }, 200)
            this.tabIndex = event.newTabIndex
          }else{
            this.$refs.tabs.currentTab = event.oldTabIndex
            this.tabIndex = event.oldTabIndex
          }
        })
      }else{
        this.tabIndex = event.newTabIndex
      }
    },

    updateData(client){
      this.edit_client = client
      this.$emit("updated", client)
    },

    async open(client, tab = "profile"){
      console.log("open",tab)
      this.edit_client = client
      if(tab === "profile") this.tabIndex = 0
      if(tab === "settings") this.tabIndex = 1
      if(tab === "goal") this.tabIndex = 2
      if(tab === "log") this.tabIndex = 3
      if(tab === "calendar") this.tabIndex = 4
      if(tab === "subscription") this.tabIndex = 5
      if(tab === "alert") this.tabIndex = 6
      if(tab === "card") this.tabIndex = 7
      this.handleChangeTicket = false
      await httpClient
        .get(`/cmp/floor/clients/${this.edit_client.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.original_client = _.cloneDeep(res.data.data.client)
            this.edit_client = res.data.data.client
            this.showEditClientModal = true
            setTimeout(()=>{
              this.edit_client_change = false
            }, 200)
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async update(){
      let self = this

      // 定期購入Onの場合は次回購入日は明日以降である必要がある
      if(this.edit_client.use_subscriptions && dayjs(this.edit_client.next_subsc_date).isBefore(dayjs())){
        window.storeCtl.commit("alert/setError", "定期購入における次回購入日は明日以降でなければなりません")
        return
      }

      // form
      const formData = new FormData();
      // icon
      if(self.img_icon){
        formData.append("img_icon", self.img_icon)
      }else{
        // 画像削除
        if(!self.edit_client.img_icon) formData.append("img_icon", "null")    
      }
      // other
      let client_obj = JSON.stringify(self.edit_client)
      formData.append('client_obj', client_obj)
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}.json`, formData, form_config)
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("company/setClient", res.data.data.client)
            self.edit_client_change = false
            self.showEditClientModal = false
            self.$emit("updated", res.data.data.client)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    calculateAge() {
      if(!this.edit_client.birth_date) return
      // 生年月日をDateオブジェクトに変換
      let birthDateObj = new Date(this.edit_client.birth_date);
      // 現在の日付を取得
      let currentDate = new Date();
      // 生まれてからの経過時間をミリ秒で計算
      let elapsedTime = currentDate - birthDateObj;
      // ミリ秒から年齢に変換
      let age = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 365.25));
      this.edit_client.age = age
    },
    copyToEveryMonth(){
      this.edit_client.stock_lesson_size = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size = this.edit_client.monthly_stock_lesson_size
      this.edit_client.stock_lesson_size_next = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size_next = this.edit_client.monthly_stock_lesson_size
      this.edit_client.stock_lesson_size_next2 = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size_next2 = this.edit_client.monthly_stock_lesson_size
    },
    changeSubscType(){
      console.log("changeSubscType")
      
      // 各ルール設定
      let subsc_type = this.edit_client.subsc_type
      let subsc_base_date = this.edit_client.subsc_base_date
      if(!this.company.allow_subsc_rule_to_client){
        subsc_type = this.company.subsc_type
        subsc_base_date = this.company.subsc_base_date
      }

      if(subsc_type != 'pay_at_first' && subsc_base_date === null) return
      let next_subsc_date = null
      // 1日
      if(subsc_type === 'pay_at_first'){
        next_subsc_date = dayjs().add(1, "M").format("YYYY-MM-01")
      }
      // 日付指定
      if(subsc_type === 'pay_at_date'){
        let base_date = dayjs()
        // 購入日が来月なら
        if(dayjs().format("D") >= subsc_base_date){
          base_date = dayjs().startOf('month').add(1, "M")
        }
        // 翌月同日付
        next_subsc_date = base_date.format(`YYYY-MM-${subsc_base_date.toString().padStart( 2, '0')}`)
        // 次月はその日付がない場合は最終日
        let isValid = dayjs(next_subsc_date).format('YYYY-MM-DD') === next_subsc_date
        if(!isValid){
          next_subsc_date = dayjs().endOf('month').format("YYYY-MM-DD")
        }
      }
      // 期間指定
      if(subsc_type === 'pay_with_term'){
        next_subsc_date = dayjs().add(subsc_base_date, "d").format("YYYY-MM-DD")
      }
      this.edit_client.next_subsc_date = next_subsc_date
    },
    changeSubscCourse(){
      let course = _.find(this.courses, ["id", this.edit_client.course_id])
      if(course){
        this.edit_client.next_subsc_name = course.name
        this.edit_client.next_subsc_price = course.price
        this.edit_client.next_subsc_ticket_number = course.ticket_number
        this.edit_client.next_subsc_expire_days = course.expire_days
        this.edit_client.next_subsc_able_menu_ids = course.able_menu_ids
        this.edit_client.next_subsc_ticket_start_next_month = course.next_subsc_ticket_start_next_month
        this.edit_client.next_subsc_ticket_end_next_month = course.next_subsc_ticket_end_next_month
        this.edit_client.next_subsc_ticket_end_style = course.next_subsc_ticket_end_style
      }else{
        this.edit_client.next_subsc_name = null
        this.edit_client.next_subsc_price = null
        this.edit_client.next_subsc_ticket_number = null
        this.edit_client.next_subsc_expire_days = null
        this.edit_client.next_subsc_able_menu_ids = []
        this.edit_client.next_subsc_ticket_start_next_month = null
        this.edit_client.next_subsc_ticket_end_next_month = null
        this.edit_client.next_subsc_ticket_end_style = null
      }
    },

    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_client.docs = new_docs
      this.edit_client.settings.cp_doc_ids = _.map(new_docs, "id")
    },
    
    // カード削除
    async deleteCard(){
      let self = this
      self.$bvModal.msgBoxConfirm('顧客のカード情報を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${this.edit_client.uid}/remove_card.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.edit_client = res.data.data.client
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "カードを削除しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })

    },

    //- ステータス変更
    changeStatus(){
      if(this.edit_client.status === "active" && this.edit_client.join_at === null) this.edit_client.join_at = dayjs().format("YYYY-MM-DD")
      if(this.edit_client.status === "disactive" && this.edit_client.destroy_at === null) this.edit_client.destroy_at = dayjs().format("YYYY-MM-DD")
    },
  },
}
