import Vue from 'vue'
import 'dayjs/locale/ja' // ローカライズのために必要
import dayjs from 'dayjs' // dayjs本体
import relativeTime from 'dayjs/plugin/relativeTime' // 相対時間表示プラグイン
import localizedFormat from 'dayjs/plugin/localizedFormat' // ローカライズフォーマットプラグイン
dayjs.extend(relativeTime) // プラグインを導入
dayjs.extend(localizedFormat) // プラグインを導入
dayjs.locale('ja') // ローカライズ
import { reserveTypeText } from '~/utils/tool'
import { nextSubscTicketEndStyles } from '~/utils/models'

let rateFormat = function (num) {
  return `${parseInt(num * 100)}%`
}
let numberFormat = function (num) {
  if (num === null) return '-'
  return (num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
let moneyFormat = function (num) {
  if (num === null) return '-'
  return (
    '￥' +
    (num || 0)
      .toString()
      .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  )
}
let byteToMbFormat = function (num) {
  if (num === null) return '-'
  let mb = _.round(parseFloat(num / (1024 * 1024)), 1)
  return (mb || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
let uppercaseFirst = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
let truncate = function (value, length) {
  if (value === null) return ''
  var length = length ? parseInt(length) : 20
  var ommision = '...'

  if (value.length <= length) {
    return value
  }

  return value.substring(0, length) + ommision
}
let timeToYYYYMMDDWeekday = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YYYY/MM/DD(ddd)')
}
let timeToYYMMDDHHMM = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YY/MM/DD HH:mm')
}
let timeToYYYYMMDDHHMM = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YYYY/MM/DD HH:mm')
}
let timeToMMDDHHMM = function (time) {
  if (!time) return '-'
  return dayjs(time).format('MM/DD HH:mm')
}
let timeToYYYYMM = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YYYY/MM')
}
let timeToYYYYMMDD = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YYYY/MM/DD')
}
let timeToYYMMDD = function (time) {
  if (!time) return '-'
  return dayjs(time).format('YY/MM/DD')
}
let timeToMMDDWeekday = function (time) {
  if (!time) return '-'
  return dayjs(time).format('MM/DD(dd)')
}
let dateToWday = function (date) {
  if (!date) return '-'
  return dayjs(date).format('dd')
}
let timeToMMDD = function (time) {
  if (!time) return '-'
  return dayjs(time).format('MM/DD')
}
let timeToHHMM = function (time) {
  if (!time) return '-'
  return dayjs(time).format('HH:mm')
}
let booleanToCanCanot = function (boolean) {
  return boolean ? '可' : '不可'
}
let booleanToDoNotDo = function (boolean) {
  return boolean ? 'する' : 'しない'
}
let booleanToYesNo = function (boolean) {
  return boolean ? 'はい' : 'いいえ'
}

let statusToText = function (status) {
  if (status === 'before') return '処理前'
  if (status === 'inprogress') return '進行中'
  if (status === 'done') return '完了'
  if (status === 'canceled') return 'キャンセル'
}
let checkBlank = function (data) {
  if (data === null || data === '') return '-'
  return data
}

let listExtendForMonthlyOptionsToText = function (limit_extend_for_monthy) {
  if (limit_extend_for_monthy === 1) return '当月中のみ有効'
  if (limit_extend_for_monthy === 2) return '2ヶ月有効'
  // if(limit_extend_for_monthy === 3) return "3ヶ月有効"
}

let review_rate_text = function (review_rate) {
  if (review_rate === '1') return '不満'
  if (review_rate === '2') return '少し不満'
  if (review_rate === '3') return '普通'
  if (review_rate === '4') return '満足'
  if (review_rate === '5') return '大変満足'
}

let muscle_pain_rate_text = function (muscle_pain_rate) {
  if (muscle_pain_rate === '1') return 'こない'
  if (muscle_pain_rate === '2') return '少し来た'
  if (muscle_pain_rate === '3') return 'すごく来た'
}

let reserve_type_text = reserve_type => {
  return reserveTypeText(reserve_type)
}

let training_event_log_text = function (status) {
  if (status === 'not_good') return '苦手'
  if (status === 'good') return '上手'
  if (status === 'very_good') return '非常に上手'
  if (status === 'done') return '完了'
  return '未設定'
}
let pofToText = function (type) {
  if (type === 'mid') return 'ミッドレンジ'
  if (type === 'contract') return 'コントラクト'
  if (type === 'stretch') return 'ストレッチ'
  if (type === 'other') return 'その他'
  return '未設定'
}

let nextSubscTicketEndStyleToText = function (value) {
  const foundItem = nextSubscTicketEndStyles.find(item => item.value === value);
  return foundItem ? foundItem.text : null;
}

Vue.filter('rateFormat', rateFormat)
Vue.filter('numberFormat', numberFormat)
Vue.filter('byteToMbFormat', byteToMbFormat)
Vue.filter('moneyFormat', moneyFormat)
Vue.filter('uppercaseFirst', uppercaseFirst)
Vue.filter('truncate', truncate)
Vue.filter('timeToYYYYMMDDWeekday', timeToYYYYMMDDWeekday)
Vue.filter('timeToYYMMDDHHMM', timeToYYMMDDHHMM)
Vue.filter('timeToYYYYMMDDHHMM', timeToYYYYMMDDHHMM)
Vue.filter('timeToMMDDHHMM', timeToMMDDHHMM)
Vue.filter('timeToYYYYMM', timeToYYYYMM)
Vue.filter('timeToYYYYMMDD', timeToYYYYMMDD)
Vue.filter('timeToYYMMDD', timeToYYMMDD)
Vue.filter('timeToMMDD', timeToMMDD)
Vue.filter('timeToMMDDWeekday', timeToMMDDWeekday)
Vue.filter('dateToWday', dateToWday)
Vue.filter('timeToHHMM', timeToHHMM)
Vue.filter('booleanToCanCanot', booleanToCanCanot)
Vue.filter('booleanToDoNotDo', booleanToDoNotDo)
Vue.filter('booleanToYesNo', booleanToYesNo)
Vue.filter('statusToText', statusToText)
Vue.filter('checkBlank', checkBlank)
Vue.filter(
  'listExtendForMonthlyOptionsToText',
  listExtendForMonthlyOptionsToText
)
Vue.filter('review_rate_text', review_rate_text)
Vue.filter('muscle_pain_rate_text', muscle_pain_rate_text)
Vue.filter('reserve_type_text', reserve_type_text)
Vue.filter('training_event_log_text', training_event_log_text)
Vue.filter('pofToText', pofToText)
Vue.filter('nextSubscTicketEndStyleToText', nextSubscTicketEndStyleToText)